import Box from '@mui/joy/Box';
import { Button } from '@mui/joy';
import Grid from '@mui/material/Grid';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import BookIcon from '@mui/icons-material/Book';

interface App {
    name: string;
    route: string;
    icon: JSX.Element; // Assuming the icons are JSX elements
    disabled: boolean;
  }
  
  // Example list of apps with types defined
  const apps: App[] = [
    { name: 'Ms. Shanti', route: '/', icon: <HealthAndSafetyIcon />, disabled: true },
    { name: 'Right to Rule', route: '/', icon: <KeyboardIcon />, disabled: true },
    { name: 'Niten Doraku', route: '/', icon: <BookIcon />, disabled: true },
    // Add more apps as needed
  ];

export default function AppGrid() {
    return (
        <Box component="main">
            <Grid container spacing={2}>
                {apps.map((app, index) => (
                <Grid item xs={4} key={index}>
                    <Button component="a" href="#as-link" startDecorator={app.icon} sx={{ width: '100%', justifyContent: 'center' }} disabled={app.disabled}>
                    {app.name}
                    </Button>
                </Grid>
                ))}
            </Grid> 
        </Box>
    )
}