import { initializeApp, FirebaseApp } from 'firebase/app';
import { getAuth, Auth } from 'firebase/auth';
import { getFirestore, Firestore } from 'firebase/firestore';

let app: FirebaseApp;
let auth: Auth;
let firestore: Firestore;

const initializeFirebase = () => {
  let firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
  };
  console.log('Firebase config from environment variables')
  console.log(firebaseConfig)

  // Fetching Firebase config from hosted file in production
  if (!process.env.REACT_APP_FIREBASE_API_KEY || process.env.REACT_APP_FIREBASE_API_KEY.length === 0) {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', '/__/firebase/init.json', false); // false for synchronous request
    xhr.send(null);

    if (xhr.status === 200) {
      firebaseConfig = JSON.parse(xhr.responseText);
      console.log('Firebase config from /__/firebase/init.json');
      console.log(firebaseConfig);
    } else {
      console.error('Failed to load Firebase config from /__/firebase/init.json');
      return;
    }
  }

  app = initializeApp(firebaseConfig);
  auth = getAuth(app);
  firestore = getFirestore(app);
};

// Invoke the function to initialize Firebase
initializeFirebase();

export { app, auth, firestore };
